import React, { forwardRef, useContext, useState } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@components/Tooltip';
import ConditionalWrapper from '@components/ConditionalWrapper';
import i18nStringsContext from '@contexts/i18nStringsContext';
import {
  Root,
  InputWrapper,
  Label,
  ValidationError,
  HasError,
  IsValid,
  ExtraSmall,
  Small,
  Medium,
  Large,
  ExtraLarge,
  Tooltip as TooltipClass,
  IsDisabled,
  AddonWrapper,
  PasswordEye,
  PasswordWrapper,
  IsVisible,
  FullWidth,
  UnderLabel,
} from './Input.module.css';

type InputSize = 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large';
type InputType =
  | 'text'
  | 'password'
  | 'number'
  | 'email'
  | 'tel'
  | 'submit'
  | 'radio'
  | 'date'
  | 'zipcode';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string | string[];
  label?: string;
  name?: string;
  htmlId?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  error?: { message?: string; type?: string };
  readableName?: string;
  inputWrapperClassName?: string;
  showValidationErrorMessage?: boolean;
  size?: InputSize;
  tooltip?: string;
  addon?: React.ReactNode;
  type?: InputType;
  append?:
    | React.ReactNode
    | (({
        isValid,
        error,
      }: {
        isValid?: boolean;
        error?: any;
      }) => React.ReactNode);
  fullWidth?: boolean;
  showValidIcon?: boolean;
  underLabel?: React.ReactNode;
  errorMessage?: string;
}

const SIZES: Record<InputSize, string> = {
  'extra-small': ExtraSmall,
  small: Small,
  medium: Medium,
  large: Large,
  'extra-large': ExtraLarge,
};

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      children,
      className,
      label,
      name,
      htmlId,
      onChange,
      onBlur,
      error,
      readableName,
      inputWrapperClassName,
      showValidationErrorMessage = true,
      size = 'medium',
      tooltip,
      addon,
      type = 'text',
      append,
      fullWidth = false,
      showValidIcon = false,
      underLabel,
      errorMessage,
      ...props
    },
    ref
  ) => {
    const [isValid, setIsValid] = useState<boolean | undefined>();
    const [passwordShown, setPasswordShown] = useState(false);
    const { fieldIsRequired, fieldIsInvalid } = useContext(i18nStringsContext);

    const classes = classnames(
      Root,
      {
        [SIZES[size]]: SIZES[size],
        [HasError]: error,
        [IsValid]:
          showValidIcon && typeof isValid !== 'undefined' && isValid && !error,
        [IsDisabled]: props.disabled,
        [FullWidth]: fullWidth,
      },
      className
    );

    const inputWrapperClasses = classnames(InputWrapper, inputWrapperClassName);

    const labelFor = props.id || name || htmlId;
    const friendlyName = readableName || label || name;

    if (label && !props.id) {
      props.id = name || htmlId;
    }

    const errorElement = error && showValidationErrorMessage && (
      <div role="alert" className={ValidationError}>
        {error?.message ||
          (error?.type === 'required'
            ? fieldIsRequired?.replace('{fieldName}', friendlyName)
            : errorMessage ||
              fieldIsInvalid?.replace('{fieldName}', friendlyName))}
      </div>
    );

    const inputField = (
      <ConditionalWrapper
        condition={!!(addon || append)}
        wrapper={(child) => <div className={AddonWrapper}>{child}</div>}
      >
        {addon ? <span>{addon}</span> : null}
        <input
          onChange={onChange}
          onBlur={(e) => {
            if (onBlur) {
              onBlur(e);
            }
            setTimeout(
              () =>
                setIsValid(e.target.getAttribute('aria-invalid') === 'false'),
              50
            );
          }}
          className={classes}
          name={name}
          {...props}
          type={passwordShown ? 'text' : type}
          ref={ref}
          aria-invalid={error ? 'true' : 'false'}
        />
        {typeof append === 'function' ? append({ isValid, error }) : append}
      </ConditionalWrapper>
    );

    const input = (
      <>
        {inputField}
        {type === 'password' && (
          <div className={PasswordWrapper}>
            <button
              type="button"
              onClick={() => setPasswordShown(!passwordShown)}
              className={classnames(PasswordEye, passwordShown && IsVisible)}
              tabIndex={0}
            >
              <FontAwesomeIcon icon={passwordShown ? faEyeSlash : faEye} />
            </button>
          </div>
        )}
        {errorElement}
      </>
    );

    return label ? (
      <div className={inputWrapperClasses}>
        <label className={Label} htmlFor={labelFor}>
          {label}
          {tooltip ? (
            <Tooltip className={TooltipClass} content={tooltip} />
          ) : null}
        </label>
        {underLabel ? <div className={UnderLabel}>{underLabel}</div> : null}
        {input}
        {children}
      </div>
    ) : (
      <>
        {input}
        {children}
      </>
    );
  }
);

export default Input;
